const USER_DASHBOARD = {
    GENERATED_REPORT_STATUS: {
        REPORT_GENERATED: 'report_generated',
        REPORT_NOT_GENERATED: 'report_not_generated',
    },
    CRIMINAL_RECORD: {
        NO_RECORD_FOUND: 'No Criminal Records Found',
    },

    ID_VERIFICATIONS: {
        SSN_VERIFIED: 'The verification was completed through SSN.',
        GOVT_ID_VERIFIED:
            'The data on the ID or Passport provided was verified in authoritative databases.',
    },
    CUSTOM_DATE_RANGE: {
        INVALID_END_DATE: 'End date should be greater than start date',
        MIN_SEVEN_DAYS_ERR: 'Minimum 7 days needed.',
        INVALID_INPUT_FORMAT: 'Not a valid date.',
    },
};

const ERR_MESSAGES = {
    NO_MVR_REPORT_FOUND: 'No existing MVR Report found.',
    NO_INTEGRATIONS_FOUND: 'No integrations found for this id.',
    ACTION_NOT_ALLOWED_FOR_DECEASED_USER: 'This action is not allowed for a deceased user.',
    DEFAULT_BROWSER_ERROR:
        'Request terminated: Possible causes include network issues, access control restrictions, or the page being unloaded.',
};

const values = { USER_DASHBOARD };

export default values;
export { USER_DASHBOARD, ERR_MESSAGES };
